export default class HrdcData {
  constructor() {
    this.APP_SERVICE_URL_HRDC = process.env.VUE_APP_SERVICE_URL_HRDC_EINVOICING;
    this.VUE_APP_ENV = process.env.VUE_APP_ENV;
    this.VUE_APP_API_URL_VISION = "https://learn.taxpod.my/api";
    if (this.VUE_APP_ENV !== "production" && this.VUE_APP_ENV !== "local") {
      this.VUE_APP_API_URL_VISION = "https://learn-dev.taxpod.my/api";
    }
    this.VUE_APP_API_AUTHORIZATION_VISION = "123abc";
    this.tinyMceApiKey = process.env.VUE_APP_TINY_MCE_API_KEY;
    this.learnTaxPodEmail = "learn@taxpod.my";
    this.isSalespersonAndCs = ["taxpod-salesperson", "taxpod-customer-service"];
    this.isSalesperson = ["taxpod-salesperson"];
    this.isHrdcAdmin = ["hrdc-admin"];
    this.isHrdcSuperAdmin = ["hrdc-super-admin"];
    this.isTaxpodAdmin = ["taxpod-admin"];
    this.isTaxpodApproval1Admin = ["hrdc-taxpod-approval-1-admin"];
    this.isTaxpodApproval2Admin = ["hrdc-taxpod-approval-2-admin"];
    this.isFinanceAdmin = ["finance-admin"];
    this.isFinanceArAdmin = ["finance-ar-admin"];
    this.isFinanceApAdmin = ["finance-ap-admin"];
    this.grantRevertOpts = [
      { text: "Grant Approved", value: 1 },
      { text: "Grant Issue", value: 0 },
    ];
    this.formRevertOpts = [
      { text: "Forms Completed", value: 1 },
      { text: "Forms Issue", value: 0 },
    ];
    (this.paymentAdviceOpts = [
      "Stripe",
      "iPay88",
      "Bank Transfer",
      "Cheque",
      "Credit Card Machine",
      "ATM Cash Deposit",
    ]),
      (this.staticPricing = [2600, 1800]); // If got new pricing, must add to index 0 (ie. [NEW_PRICE, 2600, 1800]) in ascending order
    this.eventType = {
      hrdc_einvoicing: 1,
      hrdc_tax_summit: 2,
      hrdc_tpdiy: 3,
    };
    this.paymentMethodItems = [
      "Bank Transfer",
      "Credit Card/FPX/Grab Pay/Apple Pay/Google Pay",
      "Credit Card Instalment Plan",
      "Cheque",
      "iPay88",
      "Other",
    ];
    this.speakers = ["Zen Chow", "Tifannyz", "Fong Ying", "Jeremy", "Shin"];
    this.yycMobileHotline = "+60193686868";
    this.isSalespersonAndCsRoles = false;
    this.isHrdcAdminRoles = false;
    this.isHrdcSuperAdminRoles = false;
    this.isTaxpodAdminRoles = false;
    this.isTaxpodApproval1AdminRoles = false;
    this.isTaxpodApproval2AdminRoles = false;
    this.isFinanceAdminRoles = false;
    this.isFinanceArAdminRoles = false;
    this.isFinanceApAdminRoles = false;
    this.isSalespersonRoles = false;
    this.stagePriority = null;
    this.stageSlug = null;
    this.stageIndex = null;
    this.stageSlugToStageName = null;
    this.actions = [
      {
        title: "Edit Details",
        route: "EditHrdcEinvoicingApplication",
      },
      {
        title: "General Info",
        route: "HrdcEinvoicingAddGeneralInfo",
      },
      {
        title: "Send HRDC Email to Client",
        route: "HrdcEinvoicingSendEmailToClient",
      },
      {
        title: "Grant Approval & Revert Form",
        route: "HrdcEinvoicingClientRevertForm",
      },
      {
        title: "Manual Invoice to HRDC",
        route: "HrdcEinvoicingManualInvoiceToHrdc",
      },
      {
        title: "Claim Submitted to HRDC",
        route: "HrdcEinvoicingClaimSubmitToHrdc",
      },
      {
        title: "Claim Approved from HRDC",
        route: "HrdcEinvoicingClaimApprovedFromHrdc",
      },
      {
        title: "Payment Received from HRDC",
        route: "HrdcEinvoicingPaymentReceivedFromHrdc",
      },
    ];

    // Constant color code for HRDC.
    this.HRDC_CARD_COLOR = "#ECEFF1";
    this.HRDC_BLUE_THEME_COLOR = "#063058";

    // XERO static data.
    this.ADDRESS_TYPE = "POBOX";
    this.INVOICE_TYPE = "ACCREC";

    this.allStages = fetch(
      `${this.APP_SERVICE_URL_HRDC}/v1/en/console/stage/get-stages`
    )
      .then((res) => res.json())
      .then((result) => {
        // Process stage data and return.
        const allStage = [];
        const allStagePriority = [];
        const allStageSlug = [];
        const allStageIndex = [];
        const allStageSlugToStageName = [];
        for (let i = 0; i < result.data.length; i++) {
          let resultData = result.data[i];
          let stageName = resultData.name.toUpperCase().replace(/ /g, "_");
          let stageNameSlug = resultData.nameSlug;

          allStage[stageName] = resultData.id;
          allStagePriority[stageNameSlug] = resultData.id;
          allStageSlug[stageNameSlug] = resultData.stagePriority;
          allStageIndex[resultData.id] = resultData.stagePriority;
          allStageSlugToStageName[stageNameSlug] = resultData.name;
        }

        this.stagePriority = allStagePriority;
        this.stageSlug = allStageSlug;
        this.stageIndex = allStageIndex;
        this.stageSlugToStageName = allStageSlugToStageName;
        return allStage;
      });
  }
  rolesAssignation(roles) {
    roles.forEach((item) => {
      if (this.isSalespersonAndCs.includes(item.slug)) {
        this.isSalespersonAndCsRoles = true;
      }
      if (this.isHrdcAdmin.includes(item.slug)) {
        this.isHrdcAdminRoles = true;
      }
      if (this.isHrdcSuperAdmin.includes(item.slug)) {
        this.isHrdcSuperAdminRoles = true;
      }
      if (this.isTaxpodAdmin.includes(item.slug)) {
        this.isTaxpodAdminRoles = true;
      }
      if (this.isTaxpodApproval1Admin.includes(item.slug)) {
        this.isTaxpodApproval1AdminRoles = true;
      }
      if (this.isTaxpodApproval2Admin.includes(item.slug)) {
        this.isTaxpodApproval2AdminRoles = true;
      }
      if (this.isFinanceAdmin.includes(item.slug)) {
        this.isFinanceAdminRoles = true;
      }
      if (this.isFinanceArAdmin.includes(item.slug)) {
        this.isFinanceArAdminRoles = true;
      }
      if (this.isFinanceApAdmin.includes(item.slug)) {
        this.isFinanceApAdminRoles = true;
      }
      if (this.isSalesperson.includes(item.slug)) {
        this.isSalespersonRoles = true;
      }
    });
  }
  getBasePricing(price) {
    for (const pricing of this.staticPricing) {
      if (price % pricing == 0) {
        return pricing;
      }
    }

    return false;
  }
}
